@use '../variables/variables' as *;

// Background Colors
.bg-primary {
    background-color: var(--primary-color) !important;
}

.bg-secondary {
    background-color: var(--secondary-color) !important;
}

.bg-danger {
    background-color: var(--danger-color) !important;
}

.bg-warning {
    background-color: var(--warning-color) !important;
}

.bg-info {
    background-color: var(--info-color) !important;
}

.bg-darkgrey {
    background-color: var(--darkgrey-color) !important;
}

.bg-invert {
    background-color: var(--text-color) !important;
}

.bg-lightgrey {
    background-color: var(--lightgrey-color) !important;
}

.bg-lightgray-border {
    background-color: var(--light-gray-border-color) !important;
}

.bg-switch-off {
    background-color: var(--switch-off-container-color) !important;
}

.bg-success {
    background-color: var(--success-color) !important;
}

.info-bg-success {
    background-color: var(--info-bg-color) !important;
}

.info-bg-danger {
    background-color: var(--danger-bg-color) !important;
}

.bg-blue {
    background-color: var(--blue-color) !important;
}

.bg-gray {
    background-color: var(--gray-color) !important;
}

.bg-auth-gradiant {
    background: var(--default-gradiant-auth-background-color);
}

.bg-transparent {
    background-color: transparent !important;
}

// Text Colors
.text-success {
    color: var(--success-color) !important;
}

.text-primary {
    color: var(--text-color) !important;
}

.text-danger {
    color: var(--danger-color) !important;
}

.text-danger {
    color: var(--danger-color) !important;
}

.text-warning {
    color: var(--warning-color) !important;
}

.text-info {
    color: var(--text-info-color) !important;
}

.a-blue {
    color: var(--blue-color) !important;
    text-decoration: none;
    cursor: pointer;
}

.text-info-success {
    color: var(--info-success-color) !important;
}

.bg-light-gray {
    background-color: var(--light-gray-background-color) !important;
}

.bg-orange {
    background-color: var(--warning-color) !important;
}

.bg-linear-blue {
    background-color: var(--default-gradient-background-color) !important;
}

.text-secondary {
    color: var(--secondary-color) !important;
}

.text-light-gray {
    color: var(--light-gray-text-color) !important;
}

.text-beige {
    color: var(--beige-color) !important;
}

.text-info {
    color: var(--info-color);
}