@use '../variables/variables' as *;

.acr-input:-webkit-autofill,
.acr-input:-webkit-autofill:hover, 
.acr-input:-webkit-autofill:focus {
    transition: all 5000s ease-in-out 0s;
    background-color: currentColor !important;
    color: currentColor !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.acr-input-group {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: var(--darkgrey-color);
}

.acr-input-group input {
    border: none;
    outline: none;
    background-color: var(--darkgrey-color);
    color: var(--text-color);
    padding-left: 8px;
}

.acr-input {
    border: none;
    outline: none;
    height: 50px;
    background-color: var(--darkgrey-color);
    box-shadow: none;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 400;
    // margin-bottom: 10px;
}

.acr-input::placeholder {
    color: var(--text-color);
    opacity: 0.3;
}

.acr-input-label {
    text-align: left;
    margin: 0px 0px 4px 4px;
}

.eye-icon {
    position: absolute;
    right: 16px;
    top: 35%;
    cursor: pointer;
}

.input-label {
    position: absolute;
    color: var(--text-color);
    opacity: 0.3;
    left: 16px;
    top: 15px;
    line-height: normal;
    letter-spacing: .5px;
    transition:0.2s ease all; 
    pointer-events: none;
}

.acr-input:focus {
    color: var(--text-color) !important;
    background-color: var(--darkgrey-color) !important;
    box-shadow: none !important;
}

.acr-input:focus ~ .input-label,
.acr-input:not(:placeholder-shown):valid ~ .input-label,
.acr-input:focus ~ .input-label:not(:placeholder-shown):valid {
    top: -8px;
    opacity: 1;
    background-color: var(--secondary-color);
}

input[type=checkbox].acrylic-checkbox {
    appearance: none;
    -webkit-appearance: none;
    display: flex;
    align-content: center;
    justify-content: center;
    border: 3px solid var(--light-gray-border-color);
    border-radius: 5px;
}

input[type="checkbox"].acrylic-checkbox::before {
    content: "";
    width: 24px;
    height: 24px;
    clip-path: polygon(17% 54%, 28% 43%, 38% 54%, 70% 22%, 81% 33%, 38% 75%, 17% 54%);
    transform: scale(0);
    background-color: var(--primary-color);
}

input[type=checkbox].acrylic-checkbox:checked {
    background-color: var(--info-color);
}

input[type=checkbox].acrylic-checkbox:checked::before {
    transform: scale(1.2);
}

input[type=radio].acrylic-radio {
    &:checked {
        +label {
            background: var(--default-gradient-color);
            color: var(--primary-color);
        }
    }
    &:not(:checked) {
        +label {
            color: var(--text-color);
        }
    }
}

.acrylic-select {
    .select-down-arrow {
        background-image: url('/assets/images/icons/down-arrow.svg');
        background-repeat: no-repeat;
        margin-bottom: -8px;
    }
        
    .content .acrylic-select-options {
        max-height: 200px;
    }
    
    .acrylic-select-options li:hover,
    li.selected {
        background-color: var(--light-gray-border-color);
    }
    
    .acrylic-select-options::-webkit-scrollbar {
        display: none;
    }
    
    .acrylic-select-options {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

}

textarea{
    height: 100px !important;
}

input:disabled {
    background-color: var(--disable-color) !important;
    cursor: not-allowed;
}