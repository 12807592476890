@use '../variables/variables' as *;
@use '../custom/fonts' as *;
@use '../custom/border' as *;
@use '../overrides/input' as *;

html,
body {
    height: 100%;
}

img {
    max-width: 100%;
}

body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 10px;
    font-size: 14px;
    color: var(--text-color);
    overflow-x: hidden;
}

p {
    margin-bottom: 0;
}

::selection {
    color: var(--text-color);
    background-color: var(--info-color);
}

.bg-primary-gradient {
    background: var(--primary-radial-gradient-color);
}

.acr-box {
    background: var(--secondary-color);
    border-radius: 5px;
    color: var(--text-color);
    padding: 12px;
}

.acr-ibox {
    height: 120px !important;
    padding: 15px !important;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.d-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.d-flex-align-center {
    display: flex;
    align-items: center;
}

.d-flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.d-flex-none {
    flex: none;
}

.d-flex-justify-between {
    display: flex;
    justify-content: space-between;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--secondary-color);
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--warning-color);
}

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background-color: var(--secondary-color);
    margin-right: 5px;
    border-radius: 10px;
}

.w-90 {
    width: 90%;
}

.h-50px {
    height: 50px !important;
}

.auth-tagline {
    font-weight: bold;
    background: linear-gradient(70deg, #F4F4F4 0%, #DBC39B 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3rem;
    margin-bottom: 25px;
    font-family: system-ui;

    @media only screen and (max-width: 500px) {
        font-size: 2rem;
    }
}

.line {
    flex-grow: 1;
    height: 1px;
    background-color: var(--text-color);
}

.cursor-pointer {
    cursor: pointer;
}

.bg-img {
    position: relative;
    background-size: cover;
    background-position: center;
}

.bg-img::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}

.acr-sub-box {
    background: var(--sub-box-color) !important;
}

.resize-none {
    resize: none;
}

.p-12 {
    padding: 12px !important;
}

.height-1 {
    height: 1px;
}

.height-36 {
    height: 36px;
}

.width-36 {
    width: 36px;
}

.clip-path-tag {
    width: 150px;
    clip-path: var(--clip-path-tag-shap);
}

.height-15px {
    height: 15px;
}

.width-15px {
    width: 15px;
}

.height-40 {
    height: 40px;
}

/**Pill Chip**/
.pill-count {
    background: var(--danger-color);
    font-size: 12px;
    font-weight: 400;
    padding: 2.5px 6px;
}

.border-maroon {
    border: 1px solid var(--danger-color);
}

// Pill of success, Pending
.tb-pill {
    border-radius: 5px;
    padding: 2px 10px;
    background-color: var(--light-gray-100);

    &.success {
        background-color: var(--info-bg-color);
        color: var(--success-color);
    }

    &.created {
        background-color: var(--info-bg-second-color);
        color: var(--text-info-color);
    }
}

@media(max-width:1024px) {
    .w-mobile-100 {
        width: 100% !important;
    }
}

/**Custom Select Override Css**/
.my-track-select-box {
    .select-btn {
        height: 33px;
    }
}

/**Custom Select Override Css**/
/**Custom Menu List Override Css**/
.menu-list:nth-child(even) {
    background: var(--lightgrey-color);
}

/**Custom Menu List Override Css**/
.hidden {
    opacity: 0;
    pointer-events: none;
}

.modal-open .modal-backdrop {
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-column {
    flex-direction: column !important;
}

.required:after {
    content: " *";
    color: var(--danger-color);
}

/**Profile Page Css**/
.h-bg-profile{
    height: 230px;
}

.diego-img {
    top: calc(-100% + 55%);
    left: calc(-100% + 93%);
}

.bottom-10px{
    bottom: 10px;
}

.pinned-sync-box {
    height: 170px;
    .pinned-sync-back {
        background-repeat: no-repeat;
        background-size: cover;
        .pinned-sync-gradient-layer {
            background: var(--default-gradient-gray-background-color);
        }
        .pinned-sync-content {
            position: relative;
            z-index: 1;
        }
        .pinned-sync-edit {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}
