@use "../variables/variables" as *;

.btn{
    font-size: 14px;
	border-radius: 5px;
    border: none;
}

.btn-sm{
    font-size: 13px !important;
}

.btn-acrylic {
    background: var(--darkgrey-color) !important;
    color: var(--text-color) !important;
    border: 0.3px solid var(--light-gray-border-color) !important;
}

.btn-warning{
    background: var(--warning-color) !important;
    color: var(--text-color) !important;
}

.btn-info{
    background: var(--info-color) !important;
    color: var(--text-color) !important;
}

.btn-darkgrey {
    background: var(--darkgrey-color) !important;
    color: var(--text-color) !important;
}

.btn-main{
    background: var(--default-gradient-color) !important;
    color: var(--primary-color) !important;
}

.btn-main:hover {
	background: var(--default-gradient-hover-color);
	color: var(--primary-color);
}

.btn-main:focus {
	background: var(--default-gradient-focus-color);
}

.btn-blue-grey {
    background: var(--blue-grey-color) !important;
    color: var(--text-color) !important;
}

.acrylic-next-button:disabled {
    background-color: var(--darkgrey-color) !important;
    color: var(--light-gray-table-border-color) !important;
}

.btn-lightgray {
    background-color: var(--light-gray-border-color) !important;
    color: var(--text-color) !important;
}

.btn-warning-outline {
    border-color: var(--warning-color) !important;
    color: var(--warning-color) !important;
}