/* Base Colors */
:root {
    --success-color: #05DB67;
    --darkgrey-color: #242424;
    --switch-on-wrapper-color: #454545;
    --switch-off-container-color: #2E2E2E;
    --default-gradient-color: linear-gradient(180deg, #F4F4F4 25.13%, #B8B8B8 100%);
    --default-gradient-hover-color: linear-gradient(180deg, #F4F4F4 25.13%, #B8B8B8 100%);
    --default-gradient-focus-color: linear-gradient(180deg, #F4F4F4 25.13%, #B8B8B8 100%);
    --primary-radial-gradient-color: radial-gradient(circle, rgba(84, 191, 187, 1) 0%, rgba(13, 66, 153, 1) 2%, rgba(9, 18, 23, 1) 100%);
    --default-gradient-background-color: linear-gradient(180deg, #28547E 0%, rgba(40, 84, 126, 0) 100%);
    --default-gradient-gray-background-color: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.6) 71.36%);
    --default-gradiant-auth-background-color: linear-gradient(0deg, rgba(6, 6, 6, 0.6), rgba(6, 6, 6, 0.6)), linear-gradient(180deg, rgba(0, 0, 0, 0) 56.34%, rgba(0, 0, 0, 0.8) 100%);
    --info-success-color: #6CAB9A;
    --beige-color: #DBC39B;
    --lightgrey-color: #1b201f;
    --text-info-color: #4CCEC8;
    --gray-color: #202020;
    --light-gray-border-color: #FFFFFF1A;
    --light-gray-background-color: #FFFFFF08;
    --light-gray-table-border-color: #FFFFFF0D;
    --light-gray-text-color: #FFFFFF80;
    --light-gray-100: #FFFFFF1F;
    --blue-grey-color: #25323b;
    --sub-box-color: #1A1916;
    --clip-path-tag-shap: polygon(75% 0%, 90% 48%, 75% 100%, 0% 100%, 0 44%, 0 0);
    --light-bg-first-color: #FFFFFF07;
    --light-bg-second-color: #FFFFFF05;
    --light-bg-thired-color: #FFFFFF04;
    --info-bg-color: #05DB671F;
    --info-bg-second-color: #00292b;
    --danger-bg-color: #EE593B1F;
    --darkgreen-color: #05DB6752;
    --disable-color: #ffffff47
}

/* Dark Theme */
:root .dark-theme {
    --primary-color: #060606;
    --secondary-color: #141414;
    --text-color: #FFFFFF;
    --info-color: #29a7a2; //54BFBB //6A9391
    --danger-color: #EE593B;
    --warning-color: #EBA452;
    --blue-color: #5c86f0;
}

/* Light Theme */
:root .light-theme {
    --primary-color: #F4F4F4;
    --secondary-color: #B8B8B8;
    --text-color: #000000;
    --info-color: #54BFBB;
    --danger-color: #EE813B;
    --warning-color: #EBA452;
    --blue-color: #457aff;
}