.radius-5 {
    border-radius: 5px;
}

.light-gray-border {
    border: 1px solid var(--light-gray-border-color) !important;
}

.light-gray-table-border {
    border: 1px solid var(--light-gray-table-border-color);
}

.card-beige-border {
    border: 1px solid var(--beige-color);
}

.bl-info{
    border-left: 3px solid var(--info-color);
}

.bl-danger{
    border-left: 3px solid var(--danger-color);
}